import "./AboutUsPage.css";
import companyLogo from "../images/logo_honest_option.jpg";
import profileSteve from "../images/profile_steve.jpg";
import profileRandy from "../images/profile_randy.jpg";
import profileTamela from "../images/profile_tamela.jpeg";

const teamMembers = [
  {
    name: "Steve Liang",
    title: "Executive Partner",
    image: profileSteve,
    bio: `With over a decade of experience in the real estate industry, Steve specializes in helping motivated homeowners sell their properties quickly, efficiently, and for the best possible value. As the executive partner of Honest Option House Buyers, Steve understands that selling a home can be a significant decision, and he is here to make the process as smooth and stress-free as possible.

    Steve has a proven track record of delivering results, whether it’s through creative financing or leveraging his extensive network of buyers and investors. He takes pride in offering personalized solutions tailored to your unique situation, ensuring you feel confident and supported every step of the way.

    If you’re looking to sell your home fast and without the hassle, Steve is here to help. He believes that selling your home should be a positive experience, and he is committed to providing transparent communication, fair offers, and a seamless process.

    Let’s get your house sold on your terms. Contact us today for a no-obligation consultation and take the first step toward your next chapter.`
  },
  {
    name: "Randy Winograd",
    title: "Mortgage Expert",
    image: profileRandy,
    bio: `Randy is a seasoned mortgage expert with a knack for connecting commercial investors, developers, and business owners with the perfect financial solutions since 1985. Born in the vibrant streets of Brooklyn, NY, Randy's journey in financial services has been marked by a blend of professionalism, creativity, and a friendly approach. Holding a Master of Science in Management from Florida International University, he brings a wealth of knowledge and innovative thinking to each client relationship.

    Randy's dedication to finding the right mortgage options has earned him a reputation as a trusted advisor in the industry. When he's not crafting tailored financial solutions, he relishes exploring the world with his beloved wife and experimenting with new recipes in the kitchen, blending flavors with the same finesse he brings to his work.`
  },
  {
    name: "Tamela Henderson",
    title: "Real Estate Investor & Entrepreneur",
    image: profileTamela,
    bio: `Tamela was born in a small town in Texas, where she had the privilege of growing up on a farm near her grandparents, aunts, uncles, and cousins. This close-knit family environment instilled in her a deep appreciation for the love of family, the value of hard work, and the joy that comes from living what she considers 'a simple life.'

    After graduating from Texas A&M University with a Bachelor of Science degree, Tamela began her professional journey in sales. She had the opportunity to work with several companies recognized on Forbes' Top 100 list, where she honed her skills in communication, negotiation, and client relations. However, her career took a turn when she decided to pursue real estate, and in 1999, she became a realtor. Since then, her passion for real estate has only grown.

    In 2003, Tamela ventured into entrepreneurship, marking a significant chapter in her life. Over the years, she has been involved in buying, holding, and flipping over 60 residential and commercial properties, gaining extensive experience and success in both the real estate and investment sectors. This entrepreneurial spirit has allowed her to blend her love for real estate with her desire for continuous growth and challenge. Her current focus is on providing quality housing in smaller communities, where she believes she can make a significant impact.`
  }
];

export default function AboutUsPage() {
  return (
    <div className="container">
      <div className="about-section w-screen h-[40vh] text-white py-20 shadow-lg flex justify-center items-center bg-gray-900">
        <div className="text-center">
          <h1 className="text-4xl font-bold">Honest Offers, Real Solutions</h1>
          <h2 className="mt-4 text-2xl">Your Trusted Home Selling Partner</h2>
        </div>
      </div>

      <div className="w-screen flex flex-col px-6 md:flex-row items-center py-8 space-y-6 md:space-y-0 md:space-x-6">
        <img src={companyLogo} alt="company-logo" className="w-36 h-36 rounded shadow-lg" />
        <p className="text-xl text-gray-700 leading-relaxed">
          With over six decades of combined experience in real estate, spanning in house buying, funding, rehabbing, technology and marketing, our team at Honest Option House Buyers is here to provide a seamless, positive experience for your house selling journey.
          <br /><br />
          Our principles of integrity and creating win-win solutions have empowered us to help hundreds of homeowners solve their unique challenges in life over the years. We look forward to getting to know you and we hope to build a long term relationship with you.
        </p>
      </div>

      {teamMembers.map((member, index) => (
        <div key={index} className="w-screen lg:px-16 flex flex-col lg:flex-row items-center bg-gray-100 p-8 my-6 shadow-md rounded-lg">
          <img src={member.image} alt={member.name} className="w-40 h-40 md:w-56 md:h-56 rounded-full shadow-lg" />
          <div className="flex-1 md:ml-6 mt-6 md:mt-0 text-center text-start">
            <p className="text-2xl font-bold">{member.name}</p>
            <p className="text-green-600 text-lg font-semibold">{member.title}</p>
            <p className="text-gray-700 leading-relaxed mt-4">{member.bio}</p>
          </div>
        </div>
      ))}

      <div className="w-screen bg-gray-200 p-8 my-6 rounded-lg shadow-md text-start md:text-left">
        <h2 className="text-2xl font-bold text-gray-800">What Makes Us Different?</h2>
        <p className="text-gray-700 mt-4 text-xl leading-relaxed">
          We are end buyers and we look for opportunities to acquire ourselves first. This gives us the advantage of offering you the highest price possible.
          <br /><br />
          In addition, because we are long term players, we are picky in who we choose to work with. We are interested in working with hardworking, trustworthy homeowners who value building good relationships with good people. We can help each other beyond just one transaction.
          <br /><br />
          We are here to do good for our community, and we are here to make a difference for you and for everyone involved.
        </p>
      </div>
    </div>
  );
}
