import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw"; // Enables raw HTML
import { Skeleton, Button } from "antd";

interface BlogMetadata {
  id: number;
  slug: string;
  title: string;
  date: string;
  image: string;
  excerpt: string;
}

const BlogDetail: React.FC = () => {
  const { slug } = useParams<{ slug: string }>();
  const [blog, setBlog] = useState<BlogMetadata | null>(null);
  const [content, setContent] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch("/blogs.json")
      .then((response) => response.json())
      .then((data: BlogMetadata[]) => {
        const foundBlog = data.find((b) => b.slug === slug);
        if (foundBlog) {
          setBlog(foundBlog);
          return fetch(`/content/${foundBlog.slug}.md`);
        } else {
          throw new Error("Blog not found");
        }
      })
      .then((response) => response.text())
      .then((text) => {
        const cleanedContent = text.replace(/^---[\s\S]+?---/, "").trim(); // Remove frontmatter
        setContent(cleanedContent);
      })
      .catch((error) => console.error("Error loading blog:", error))
      .finally(() => setLoading(false));
  }, [slug]);

  if (loading) {
    return (
      <div className="max-w-4xl mx-auto p-6">
        <Skeleton active />
      </div>
    );
  }

  if (!blog || !content) {
    return (
      <div className="max-w-4xl mx-auto p-6 text-center text-red-500">
        <h1 className="text-3xl font-bold">Blog Not Found</h1>
        <p>The requested blog does not exist.</p>
        <Link to="/blog" className="text-blue-600 hover:underline">
          Go back to Blog List
        </Link>
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto p-6">
      <img
        src={blog.image}
        alt={blog.title}
        className="w-full h-64 object-cover rounded-lg mb-4"
      />
      <h1 className="text-4xl font-bold">{blog.title}</h1>
      <p className="text-gray-500 text-md mt-2 mb-4">Published on {blog.date}</p>

      {/* Markdown content with Tailwind's prose styling */}
      <div className="prose prose-lg prose-blue max-w-none">
        <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]}>
          {content}
        </ReactMarkdown>
      </div>

      <div className="mt-8 m-auto w-full max-w-md">
        <Button
          type="primary"
          size="large"
          href="/contact"
          className="font-bold bg-teal-600 border-teal-600 hover:bg-teal-700 hover:border-teal-700 flex items-center justify-center"
        >
          Get My Cash Offer Today
        </Button>
      </div>

      <div className="mt-8">
        <Link to="/blog" className="text-blue-600 hover:underline">
          ← Back to Blog
        </Link>
      </div>
    </div>
  );
};

export default BlogDetail;
